export const COLORS = {
	// Adxiom
	darkBackgroundCard: '#040F21',
	lightBackgoundCard: '#061631',
	pink: '#D94A8F',
	blue: '#34CBCB',

	// Mr Code
	// darkBackgroundCard: '#0C1015',
	// lightBackgoundCard: '#212d3b',
	// pink: '#e6b450',
	// blue: '#d95757',
}
